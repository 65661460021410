<template>
  <div
    :style="{ height: clientHeight - 180 + 'px' }"
    style="overflow-y: scroll"
  >
    <div class="big">
      <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
        <el-tab-pane label="弹窗规则" name="1">
          <popUpRule></popUpRule>
        </el-tab-pane>
        <el-tab-pane label="链接地址" name="2">
          <linkAddress></linkAddress>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import linkAddress from "./linkaddress.vue";
import popUpRule from "./popuprule.vue";
export default {
  components: { linkAddress, popUpRule },
  data() {
    return {
      clientHeight: document.body.clientHeight,
      activeName: "1",
    };
  },
  mounted() {
    const that = this;
    window.onresize = () => {
      return (() => {
        window.screenHeight = document.body.clientHeight;
        that.clientHeight = window.screenHeight;
      })();
    };
  },
  watch: {
    clientHeight(val) {
      // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
      if (!this.timer) {
        // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
        this.clientHeight = val;
        this.timer = true;
        let that = this;
        setTimeout(function () {
          // 打印screenWidth变化的值
          console.log(that.clientHeight);
          that.timer = false;
        }, 400);
      }
    },
  },
  methods: {
    handleClick(tab, event) {
      console.log("tab,event", tab, event);
    },
  },
};
</script>

<style lang='less' scoped>
/deep/.el-table {
  .cell {
    font-size: 14px !important;
  }
}
.big {
  .el-tabs {
    margin: 30px 40px 0 0px;
    margin-left: 25px;
  }
  /deep/.el-tabs--card > .el-tabs__header,
  .el-tabs--card > .el-tabs__header .el-tabs__nav {
    border: none;
  }
  /deep/.el-tabs__item.is-active {
    color: #9d6052;
    background: url("../../assets/images/tab.png") no-repeat;
    border: 1px solid #9d6052;
  }
  /deep/.el-tabs__item:hover {
    color: #9d6052;
  }
  /deep/ .el-tabs__item {
    height: 60px;
    line-height: 60px;
    font-size: 20px;
    padding: 0 65px;
    color: #d78673;
    border: none;
    border: 1px solid #9d6052;
    border-left: 1px solid #9d6052 !important;
    padding-left: 65px !important;
    padding-right: 65px !important;
    outline: none;
  }
  /deep/.el-form-item__label {
    color: #909399;
  }
}
</style>
<template>
  <div>
    <div class="top">
      <el-table :data="tableData" style="width: 100%" :show-header="false">
        <el-table-column prop="title" :label="title" width="180">
        </el-table-column>
        <el-table-column prop="value" :label="value" width="600">
        </el-table-column>
        <el-table-column label="" width="100">
          <template slot-scope="scope">
            <el-button size="mini" @click="handleEdit(scope.$index, scope.row)"
              >编辑</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="bottom">
        <div class="bottom-left">
          <el-button round class="top-btn" @click="addList">增加</el-button>
        </div>
      </div>
      <!-- 增加按钮的弹框 -->
      <el-dialog
        title="增加"
        :visible.sync="dialogVisible"
        width="45%"
        :before-close="handleClose"
      >
        <el-form :inline="true" :model="formInline" class="demo-form-inline">
          <el-form-item label="标题">
            <el-input
              v-model="formInline.title"
              placeholder="输入弹窗标题"
              style="width: 200px"
            ></el-input>
          </el-form-item>
          <el-form-item label="链接">
            <el-input
              v-model="formInline.value"
              placeholder=""
              style="width: 100%"
            ></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="addLink">保存</el-button>
        </span>
      </el-dialog>
      <!-- 修改按钮的弹框 -->
      <el-dialog
        title="编辑"
        :visible.sync="dialogVisible1"
        width="45%"
        :before-close="handleClose1"
      >
        <el-form :inline="true" :model="formInline" class="demo-form-inline">
          <el-form-item label="标题">
            <el-input
              v-model="formInline.title"
              placeholder="输入弹窗标题"
              style="width: 200px"
            ></el-input>
          </el-form-item>
          <el-form-item label="链接">
            <el-input
              v-model="formInline.value"
              placeholder=""
              style="width: 400px"
            ></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="cancel">取 消</el-button>
          <el-button type="primary" @click="upLink">保存</el-button>
        </span>
      </el-dialog>
      <!-- <el-form
        ref="form"
        :model="form"
        label-width="100px"
        size="mini"
        style="width: 80%; margin-top: 2%"
        class="demo-dynamic"
      >
        <el-form-item :label="form.title" style="width: 55%">
          <el-input v-model="form.value"></el-input>
          <el-button round class="top-btn" @click="编辑">编辑</el-button>
        </el-form-item>
        <el-form-item label="用户协议" style="width: 55%">
          <el-input v-model="form.linkTwo"></el-input>
        </el-form-item>
        <el-form-item label="服务协议" style="width: 55%">
          <el-input v-model="form.linkThree"></el-input>
        </el-form-item>
        <el-form-item label="远盟救援隐私" style="width: 55%">
          <el-input v-model="form.linkfour"></el-input>
        </el-form-item>
        <el-form-item label="远盟急救协议" style="width: 55%">
          <el-input v-model="form.linkfive"></el-input>
        </el-form-item>
        <el-form-item label="康康救援协议" style="width: 55%">
          <el-input v-model="form.linksix"></el-input>
        </el-form-item>
        <el-form-item label="会员中心" style="width: 55%">
          <el-input v-model="form.linkseven"></el-input>
        </el-form-item>
        <el-form-item style="width: 55%">
          <el-button type="primary" @click="onSubmit">保存</el-button>
        </el-form-item>
      </el-form> -->
    </div>
  </div>
</template>

<script>
import { apiShout } from "../../api1/configShout";
export default {
  data() {
    return {
      form: {
        link: "",
        linkTwo: "",
        linkThree: "",
        linkfour: "",
        linkfive: "",
        linksix: "",
        linkseven: "",
      },
      title: "",
      value: "",
      tableData: [],
      dialogVisible: false,
      dialogVisible1: false,
      formInline: {
        title: "",
        value: "",
      },
      rowId: "",
    };
  },
  created() {
    this.AppList();
  },
  methods: {
    AppList() {
      apiShout.getApp().then((res) => {
        // console.log("列表：", res.data);
        if (res.data.code == 200) {
          this.tableData = res.data.result;
          // this.form=res.data.result;
        }
      });
    },
    addList() {
      this.dialogVisible = true;
      this.formInline = {};
    },
    addLink() {
      apiShout.getinApp(this.formInline).then((res) => {
        console.log("增加：", res.data);
        if (res.data.code == 200) {
          this.dialogVisible = false;
          this.$message({
            message: "恭喜你,成功增加链接",
            type: "success",
          });
          this.AppList();
          this.formInline = {};
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    upLink() {
      apiShout
        .getupApp({
          id: this.rowId,
          title: this.formInline.title,
          value: this.formInline.value,
        })
        .then((res) => {
          console.log("修改：", res.data);
          if (res.data.code == 200) {
            this.dialogVisible1 = false;
            this.$message({
              message: "恭喜你,成功修改链接",
              type: "success",
            });
            this.AppList();
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    handleEdit(index, row) {
      console.log(index, row);
      this.dialogVisible1 = true;
      this.formInline = row;
      this.rowId = row.id;
    },
    handleClose() {
      this.dialogVisible = false;
    },
    handleClose1() {
      this.dialogVisible1 = false;
    },
    cancel() {
      this.dialogVisible1 = false;
      this.formInline = {};
    },
    onSubmit() {
      console.log("submit!");
    },
  },
};
</script>

<style lang='less' scoped>
.el-form {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.top-btn {
  background: #d78673;
  margin-left: 30px;
  color: #ffffff;
  margin-top: 10px;
  font-size: 15px;
}
.bottom-left {
  float: left;
}
//弹框
/deep/ .el-dialog__body {
  margin: 20px;
}

// table 表格所有的格式
.tables {
  .el-table::before {
    height: 0;
  }
  .el-table {
    td,
    th.is-leaf {
      border: none !important;
    }
    tr th {
      font-size: 25px;
      color: #706d6d;
      text-align: center;
    }
    tr td {
      text-align: center;
      font-size: 25px;
      color: #a8a8a8;
    }
  }
}
/deep/ .el-table .el-table__cell {
  padding: 12px 0;
  min-width: 0;
  box-sizing: border-box;
  text-overflow: ellipsis;
  vertical-align: middle;
  position: relative;
  text-align: center;
  font-size: 17px;
}
/deep/ .el-table th.el-table__cell > .cell {
  display: inline-block;
  box-sizing: border-box;
  position: relative;
  vertical-align: middle;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
  font-size: 18px;
}
/deep/ .el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
  border-bottom: none;
}
/deep/ th.el-table__cell.is-leaf {
  border-bottom: none;
}
/deep/ .el-button--mini,
.el-button--mini.is-round {
  padding: 7px 15px;
  font-size: 15px;
}
/deep/.el-button.is-round {
  padding: 8px 22px;
}
/deep/.el-table td,
.el-table th.is-leaf {
  border-bottom: none !important;
}
/deep/.el-table .cell {
  text-align: center !important;
}
/deep/.el-table thead tr th.is-leaf {
  border: none;
  border-right: none;
}
</style>
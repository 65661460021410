<template>
  <div class="tables">
    <el-table :data="tableData" style="width: 100%">
      <el-table-column prop="numbering" label="序号"> </el-table-column>
      <el-table-column prop="title" label="弹窗标题"> </el-table-column>
      <el-table-column prop="startDate" label="生效时间"> </el-table-column>
      <el-table-column prop="endDate" label="结束时间"> </el-table-column>
      <el-table-column prop="jumpPositionCn" label="跳转链接">
      </el-table-column>
      <el-table-column prop="photoUrl" label="图片">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="danger"
            @click="handleSearch(scope.$index, scope.row)"
            >查看</el-button
          >
        </template>
      </el-table-column>
      <!-- <el-table-column prop="jumpPositionCn" label="特殊条件">
      </el-table-column> -->
      <!-- <el-table-column prop="name" label="特殊条件"> </el-table-column> -->
      <el-table-column prop="level" label="优先级"> </el-table-column>
      <el-table-column prop="triggerPositionCn" label="触发位置">
      </el-table-column>
      <el-table-column prop="frequencyCn" label="显示次数"> </el-table-column>
      <el-table-column label="操作" width="180">
        <template slot-scope="scope">
          <el-button size="mini" @click="handleEdit(scope.$index, scope.row)"
            >编辑</el-button
          >
          <el-button
            size="mini"
            type="danger"
            @click="handleDelete(scope.$index, scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="bottom">
      <div class="bottom-left">
        <el-button round class="top-btn" @click="addList">增加弹窗</el-button>
      </div>
      <div class="bottom-right">
        <div class="bottom-fen">
          <!-- 分页 -->
          <div class="pages">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :page-size="pageSize"
              :current-page="pageCount"
              layout="total, prev, pager, next"
              :total="totalCount"
            >
            </el-pagination>
          </div>
        </div>
        <!-- <div class="buttons">
          <el-button>数据分析</el-button>
          <el-button @click="dataExport">数据导出</el-button>
        </div> -->
      </div>
    </div>
    <!-- 增加按钮的弹框 -->
    <el-dialog
      title="增加"
      :visible.sync="dialogVisible"
      width="50%"
      :before-close="handleClose"
    >
      <el-form
        :inline="true"
        :model="formInline"
        class="demo-form-inline"
        label-width="85px"
      >
        <el-form-item label="弹窗标题：">
          <el-input
            v-model="formInline.title"
            placeholder="输入弹窗标题"
            style="width: 100%"
          ></el-input>
        </el-form-item>
        <el-form-item label="优先级：">
          <el-input
            v-model="formInline.level"
            placeholder=""
            style="width: 100%"
          ></el-input>
        </el-form-item>
        <el-form-item label="上架时间：">
          <el-date-picker
            v-model="changTime"
            style="width: 100%"
            type="datetimerange"
            range-separator="-"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="弹窗类型：">
          <el-select
            v-model="valueType"
            style="width: 100%"
            clearable
            placeholder="请选择"
            @change="popUp"
          >
            <el-option
              v-for="item in optionsType"
              :key="item.code"
              :label="item.name"
              :value="item.code"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="跳转链接：">
          <el-select
            v-model="value"
            clearable
            placeholder="请选择"
            @change="link"
            style="width: 30%; margin-right: 3%"
          >
            <el-option
              v-for="item in options"
              :key="item.code"
              :label="item.name"
              :value="item.code"
            ></el-option>
          </el-select>
          <el-select
            v-model="valueIn"
            clearable
            placeholder="请选择"
            @input="$forceUpdate()"
            @change="linkOne"
            style="width: 66%"
            v-if="isShow"
          >
            <el-option
              v-for="item in optionsIn"
              :key="item.code"
              :label="item.name"
              :value="item.code"
            ></el-option>
          </el-select>
          <el-input
            v-model="formInline.jumpPosition"
            placeholder="链接"
            style="width: 66%"
            @input="$forceUpdate()"
            @change="$forceUpdate()"
            v-if="isShows"
          ></el-input>
        </el-form-item>
        <el-form-item label="广告图片：">
          <el-input
            v-model="formInline.photoUrl"
            placeholder="选择上传图片"
            @input="$forceUpdate()"
            @change="$forceUpdate()"
            id="conXiao"
            :disabled="true"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="pushImage">上传</el-button>
        </el-form-item>
        <el-form-item label="触发位置：">
          <el-select
            v-model="valueTrigger"
            clearable
            placeholder="请选择"
            @change="optionsBtn()"
          >
            <el-option
              v-for="item in optionsTrigger"
              :key="item.code"
              :label="item.name"
              :value="item.code"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="显示次数：">
          <el-select v-model="valueNum" clearable placeholder="请选择">
            <el-option
              v-for="item in optionsNum"
              :key="item.code"
              :label="item.name"
              :value="item.code"
            ></el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="特殊条件：">
          <el-select v-model="valueSpecial" clearable placeholder="请选择">
            <el-option
              v-for="item in optionsSpecial"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item> -->
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="removes">取 消</el-button>
        <el-button type="primary" @click="addWindow">保存</el-button>
      </span>
    </el-dialog>
    <!-- 编辑按钮的弹框 -->
    <el-dialog
      title="编辑"
      :visible.sync="dialogVisible1"
      width="50%"
      :before-close="handleClose1"
    >
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item label="弹窗标题：">
          <el-input
            v-model="formInline.title"
            placeholder="输入弹窗标题"
            style="width: 400px"
          ></el-input>
        </el-form-item>
        <el-form-item label="优先级：">
          <el-input
            v-model="formInline.level"
            placeholder=""
            style="width: 200px"
          ></el-input>
        </el-form-item>
        <el-form-item label="上架时间：">
          <el-date-picker
            v-model="changTime"
            type="datetimerange"
            range-separator="-"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="弹窗类型：">
          <el-select v-model="formInline.type" clearable placeholder="请选择">
            <el-option
              v-for="item in optionsType"
              :key="item.code"
              :label="item.name"
              :value="item.code"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="跳转链接：">
          <el-select
            v-model="value"
            clearable
            placeholder="请选择"
            @change="link"
          >
            <el-option
              v-for="item in options"
              :key="item.code"
              :label="item.name"
              :value="item.code"
            ></el-option>
          </el-select>
          <el-select
            v-model="valueIn"
            clearable
            placeholder="请选择"
            @change="linkOne"
            v-if="isShow"
          >
            <el-option
              v-for="item in optionsIn"
              :key="item.code"
              :label="item.name"
              :value="item.code"
            ></el-option>
          </el-select>
          <el-input
            v-model="formInline.jumpPosition"
            placeholder="链接"
            style="width: 500px"
            v-if="isShows"
          ></el-input>
        </el-form-item>
        <el-form-item label="广告图片：">
          <el-input
            v-model="formInline.photoUrl"
            placeholder="选择上传图片"
            style="width: 500px"
            id="conXiaoC"
            :disabled="true"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="pushImage1">上传</el-button>
        </el-form-item>
        <el-form-item label="触发位置：">
          <el-select v-model="valueTrigger" clearable placeholder="请选择">
            <el-option
              v-for="item in optionsTrigger"
              :key="item.code"
              :label="item.name"
              :value="item.code"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="显示次数：">
          <el-select
            v-model="formInline.frequency"
            clearable
            placeholder="请选择"
          >
            <el-option
              v-for="item in optionsNum"
              :key="item.code"
              :label="item.name"
              :value="item.code"
            ></el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="特殊条件：">
          <el-select v-model="valueSpecial" clearable placeholder="请选择">
            <el-option
              v-for="item in optionsSpecial"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item> -->
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="quxiao">取 消</el-button>
        <el-button type="primary" @click="changeWin">保存</el-button>
      </span>
    </el-dialog>
    <!-- 图片查看 -->
    <el-dialog
      title="图片"
      :visible.sync="dialogVisibleImage"
      width="30%"
      :before-close="ImagehandleClose"
    >
      <!-- <img src="http://cdn.careld.cn/datacenter/menu/product/91638329205408.png" alt=""> -->
      <el-image
        style="width: 200px; height: 200px"
        :src="productImage"
      ></el-image>
    </el-dialog>
    <!-- 增加上传图片弹框 -->
    <el-dialog
      title="上传图片"
      :visible.sync="imageVisible"
      width="30%"
      :before-close="handleCloseImage"
      append-to-body
    >
      <input type="file" ref="file" accept="image/*" @change="fileChange" />
      <span slot="footer" class="dialog-footer">
        <el-button @click="imageVisible = false">取 消</el-button>
        <el-button type="primary" @click="uploadImage">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 修改上传图片弹框 -->
    <el-dialog
      title="上传图片"
      :visible.sync="imageVisible1"
      width="30%"
      :before-close="handleCloseImage1"
      append-to-body
    >
      <input type="file" ref="file" accept="image/*" @change="fileChange1" />
      <span slot="footer" class="dialog-footer">
        <el-button @click="imageVisible1 = false">取 消</el-button>
        <el-button type="primary" @click="uploadImage1">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { apiShout } from "../../api1/configShout";
import { api } from "../../api1/config";
import * as qiniu from "qiniu-js";
// import Vue from "_vue@2.6.14@vue";
export default {
  data() {
    return {
      dialogVisible: false,
      dialogVisible1: false,
      dialogVisibleImage: false,
      productImage: "", //点击查看的图片
      formInline: {
        title: "",
        level: "",
        jumpPosition: "",
        photoUrl: "",
        jumpType: "",
        triggerPosition: "",
        type: "",
        frequency: "",
      },
      isShow: false,
      isShows: true,
      // isShow1:true,
      valueType: "",
      optionsType: [],
      valueIn: "",
      optionsIn: [
        {
          code: "1",
          name: "外部链接",
        },
        {
          code: "0",
          name: "我的",
        },
      ],
      value: "",
      options: [
        {
          code: "1",
          name: "外部链接",
        },
        {
          code: "0",
          name: "我的",
        },
      ],
      valueTrigger: "",
      optionsTrigger: [
        {
          value: "1",
          label: "启动",
        },
        {
          value: "0",
          label: "打开家",
        },
      ],
      valueNum: "",
      optionsNum: [
        {
          value: "1",
          label: "仅一次",
        },
        {
          value: "0",
          label: "每次启动",
        },
      ],
      valueSpecial: "",
      optionsSpecial: [
        {
          value: "1",
          label: "无",
        },
        {
          value: "0",
          label: "3天邀请开通救援",
        },
      ],
      tableData: [],
      pageSize: 10,
      totalCount: 100,
      pageCount: 1,
      changTime: [],
      pan: "",
      linkNei: "",
      imageVisible: false,
      imageVisible1: false,
      token: "", //上传头像的token
      accessKey: "",
      bucket: "",
      secretKey: "",
      prefix: "",
      rowId: "",
      token1: "", //上传头像的token
      accessKey1: "",
      bucket1: "",
      secretKey1: "",
      prefix1: "",
      flagChange: false,
    };
  },
  created() {
    this.winList();
  },
  methods: {
    optionsBtn() {},
    //增加点击上传图片的按钮
    pushImage() {
      this.imageVisible = true;
      //获取七牛云存储配置
      let that = this;
      that.token = JSON.parse(sessionStorage.getItem("token"));
      //console.log("token", that.token);
      api.getQiuNiu({ type: "window" }).then((res) => {
        console.log("获取七牛云存储配置：", res.data.result);
        this.token = res.data.result.token;
        this.accessKey = res.data.result.accessKey;
        this.bucket = res.data.result.bucket;
        this.secretKey = res.data.result.secretKey;
        this.prefix = res.data.result.prefix;
      });
    },
    //增加确定上传图片
    uploadImage() {
      this.imageVisible = false;
      let loading = this.$loading({
        lock: true,
        text: "上传中，请稍候...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.5)",
      });
      //获取本地
      // let bIm = JSON.parse(localStorage.getItem("backImage"));
      //清空图片
      this.fileList = [];
      // console.log("===========", this.$refs.upload.uploadFiles[0]);
      this.userinfo = JSON.parse(window.localStorage.getItem("loginuser"));
      this.id = this.userinfo.id;
      if (this.$refs.file.files.length === 0) {
        alert("请选择文件");
        return;
      }
      let file = this.$refs.file.files[0];
      let shijiancuo = new Date().getTime();
      let token = this.token;
      //处理文件拓展名
      let indexName = file.name.lastIndexOf(".");
      let ming = file.name.slice(indexName);
      const putExtra = {};
      const config = {};
      //console.log('用户：',this.id);
      const observable = qiniu.upload(
        file,
        this.prefix + this.id + shijiancuo + ming,
        token,
        putExtra,
        config
      );
      const observer = {
        complete(res) {
          console.log("hash:", res.hash, res.key);
          localStorage.setItem("popUp", JSON.stringify(res.key));
          document.getElementById("conXiao").placeholder =
            "http://cdn.careld.cn/" + res.key;
          loading.close();
        },
      };
      observable.subscribe(observer); // 上传开始
    },
    //增加上传头像格式限制
    fileChange(e) {
      console.log("-----------1111:", e.target.files);
      var file = e.target.files[0];
      if (window.FileReader) {
        debugger;
        var reader = new FileReader();
        reader.readAsDataURL(file);
        //监听文件读取结束后事件
        reader.onloadend = function (e) {
          //  console.log('地址路径',e.target.result);   //e.target.result就是最后的路径地址
          //  document.getElementById("gengaiImage").src=e.target.result
          //保存本地
          localStorage.setItem("backImage", JSON.stringify(e.target.result));
          //  this.baseImg=e.target.result;
        };
      }

      //大小限制
      var filemaxsize = 1024 * 2; //2M
      var size = file.size / 1024;
      if (size > filemaxsize) {
        alert("附件大小不能大于" + filemaxsize / 1024 + "M！");
        return false;
      }
      if (size <= 0) {
        alert("附件大小不能为0M！");
        return false;
      }
    },
    //修改点击上传图片的按钮
    pushImage1() {
      this.imageVisible1 = true;
      //获取七牛云存储配置
      let that = this;
      that.token = JSON.parse(sessionStorage.getItem("token"));
      api.getQiuNiu({ type: "window" }).then((res) => {
        console.log("获取七牛云存储配置：", res.data.result);
        this.token1 = res.data.result.token;
        this.accessKey1 = res.data.result.accessKey;
        this.bucket1 = res.data.result.bucket;
        this.secretKey1 = res.data.result.secretKey;
        this.prefix1 = res.data.result.prefix;
      });
    },
    //修改确定上传图片
    uploadImage1() {
      this.imageVisible1 = false;
      this.flagChange = true;
      let loading = this.$loading({
        lock: true,
        text: "上传中，请稍候...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.5)",
      });
      this.userinfo = JSON.parse(window.localStorage.getItem("loginuser"));
      this.id = this.userinfo.id;
      if (this.$refs.file.files.length === 0) {
        alert("请选择文件");
      }
      let file = this.$refs.file.files[0];
      let shijiancuo = new Date().getTime();
      let token = this.token1;
      //处理文件拓展名
      let indexName = file.name.lastIndexOf(".");
      let ming = file.name.slice(indexName);
      const putExtra = {};
      const config = {};
      //console.log('用户：',this.id);
      const observable = qiniu.upload(
        file,
        this.prefix1 + this.id + shijiancuo + ming,
        token,
        putExtra,
        config
      );
      const observer = {
        complete(res) {
          console.log("hash:", res.hash, res.key);
          localStorage.setItem("popUp1", JSON.stringify(res.key));
          loading.close();
          document.getElementById("conXiaoC").value =
            "http://cdn.careld.cn/" + res.key;
          console.log("====", document.getElementById("conXiaoC"));
          // document.getElementById("addAdvertImg").src=bIm;
          //存本地
          // alert('上传成功');
        },
      };
      observable.subscribe(observer); // 上传开始
    },
    //修改上传头像格式限制
    fileChange1(e) {
      var filemaxsize = 1024 * 2; //2M
      var size = e.target.files[0].size / 1024;
      if (size > filemaxsize) {
        alert("附件大小不能大于" + filemaxsize / 1024 + "M！");
        return false;
      }
      if (size <= 0) {
        alert("附件大小不能为0M！");
        return false;
      }
    },
    jumpLink() {
      api.getSelectProduct({ dictCode: "appjumptype" }).then((res) => {
        console.log("跳转链接：", res.data);
        if (res.data.code == 200) {
          this.options = res.data.result;
        }
      });
    },
    popUpList() {
      api.getSelectProduct({ dictCode: "appwindowtype" }).then((res) => {
        console.log("弹窗类型：", res.data);
        if (res.data.code == 200) {
          this.optionsType = res.data.result;
        }
      });
    },
    displayTimes() {
      api.getSelectProduct({ dictCode: "appfrequency" }).then((res) => {
        console.log("弹窗次数类型：", res.data);
        if (res.data.code == 200) {
          this.optionsNum = res.data.result;
        }
      });
    },
    link(value) {
      console.log("value:", value);
      this.pan = value;
      if (value == 1) {
        this.isShow = true;
        this.isShows = false;
        api.getSelectProduct({ dictCode: "appposition" }).then((res) => {
          console.log("内链接：", res.data);
          if (res.data.code == 200) {
            this.optionsIn = res.data.result;
          }
        });
      } else {
        this.isShow = false;
        this.isShows = true;
        this.formInline.jumpPosition = "";
      }
    },
    linkOne(aa) {
      console.log("内连接的选中：", aa);
    },
    popUp(bb) {
      console.log("弹窗类型的选中：", bb);
    },
    addWindow() {
      if (this.pan == 1) {
        this.formInline.jumpPosition = "";
      } else {
        this.valueIn = "";
      }
      if(document.getElementById("conXiao").placeholder !=""){
        this.formInline.photoUrl =
        "http://cdn.careld.cn/" + JSON.parse(localStorage.getItem("popUp"));
      }else{
        this.formInline.photoUrl ="";
      }
      let obj = {
        title: this.formInline.title,
        level: this.formInline.level,
        startDate: this.changTime[0],
        endDate: this.changTime[1],
        jumpType: this.value,
        jumpPosition: this.valueIn || this.formInline.jumpPosition,
        triggerPosition: this.valueTrigger,
        type: this.valueType,
        photoUrl: this.formInline.photoUrl,
        frequency: this.valueNum,
      };
      // console.log("输出：", obj);
      apiShout.getinWindow(obj).then((res) => {
        console.log("增加弹窗：", res.data);
        if (res.data.code == 200) {
          this.dialogVisible = false;
          this.$message({
            message: "恭喜你,成功增加弹窗信息",
            type: "success",
          });
          //刷新页面
          this.winList();
          this.formInline = {};
          this.formInline.photoUrl = "";
          this.changTime = [];
          this.value = "";
          this.valueIn = "";
          this.valueType = "";
          this.valueNum = "";
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    removes() {
      this.dialogVisible = false;
      this.formInline = {};
      this.formInline.photoUrl = "";
      this.valueTrigger = "";
      this.changTime = [];
      this.value = "";
      this.valueIn = "";
      this.valueType = "";
      this.valueNum = "";
    },
    winList() {
      apiShout
        .getwinList({ pageSize: this.pageSize, pageCount: this.pageCount })
        .then((res) => {
          console.log("弹窗列表：", res.data);
          if (res.data.code == 200) {
            this.tableData = res.data.result.data;
            this.totalCount = res.data.result.page.totalCount;
            this.pageSize = res.data.result.page.pageSize;
            this.tableData.forEach((item, index) => {
              this.$set(this.tableData[index], "numbering", index + 1);
            });
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    addList() {
      console.log(document.getElementById("conXiao"),'ccccc')
      if(document.getElementById("conXiao")!=null){
        document.getElementById("conXiao").placeholder = "";
      }
      api.getSelectProduct({ dictCode: "appposition" }).then((res) => {
        console.log("内链接：", res.data);
        if (res.data.code == 200) {
          this.optionsTrigger = res.data.result;
        }
      });
      this.dialogVisible = true;
      // this.formInline = {};
      // this.changTime = [];
      // this.value = "";
      // this.valueIn = "";
      // this.valueType = "";
      // this.valueNum = "";
      this.jumpLink();
      this.popUpList();
      this.displayTimes();
    },
    //查看
    handleSearch(index, row) {
      console.log(index, row);
      this.dialogVisibleImage = true;
      this.productImage = row.photoUrl;
    },
    handleEdit(index, row) {
      this.changTime = [];
      console.log(index, row);
      this.dialogVisible1 = true;
      this.jumpLink();
      this.popUpList();
      this.displayTimes();
      this.rowId = row.id;
      api.getSelectProduct({ dictCode: "appposition" }).then((res) => {
        console.log("内链接：", res.data);
        if (res.data.code == 200) {
          this.optionsTrigger = res.data.result;
          this.optionsIn = res.data.result;
        }
      });
      apiShout.getsearchWin({ id: row.id }).then((res) => {
        console.log("弹窗详情：", res.data);
        if (res.data.code == 200) {
          this.formInline = res.data.result;
          this.changTime.push(
            res.data.result.startDate,
            res.data.result.endDate
          );
          this.valueType = res.data.result.typeCn;
          this.value = res.data.result.jumpType;
          this.valueNum = res.data.result.frequencyCn;
          this.valueTrigger = res.data.result.triggerPosition;
          if (res.data.result.jumpType === "1") {
            //内
            this.isShow = true;
            this.isShows = false;
            this.valueIn = res.data.result.jumpPosition;
          } else {
            this.isShow = false;
            this.isShows = true;
            this.formInline.jumpPosition = res.data.result.jumpPosition;
          }
        }
      });
    },
    changeWin() {
      if (this.flagChange == true) {
        this.formInline.photoUrl =
          "http://cdn.careld.cn/" + JSON.parse(localStorage.getItem("popUp1"));
      }
      if (this.pan == 1) {
        this.formInline.jumpPosition = "";
      } else {
        this.valueIn = "";
      }
      let obj = {
        id: this.rowId,
        title: this.formInline.title,
        level: this.formInline.level,
        startDate: this.changTime[0],
        endDate: this.changTime[1],
        jumpType: this.value,
        jumpPosition: this.valueIn || this.formInline.jumpPosition,
        triggerPosition: this.valueTrigger,
        type: this.formInline.type,
        photoUrl: this.formInline.photoUrl,
        frequency: this.formInline.frequency,
      };
      // console.log('修改：',obj);
      apiShout.getupWindow(obj).then((res) => {
        console.log("修改弹窗：", res.data);
        if (res.data.code == 200) {
          this.dialogVisible1 = false;
          this.$message({
            message: "恭喜你,成功修改弹窗信息",
            type: "success",
          });
          this.winList();
          this.flagChange = false;
          this.formInline = {};
          this.formInline.photoUrl = "";
          this.changTime = [];
          this.value = "";
          this.valueIn = "";
          this.valueType = "";
          this.valueNum = "";
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    handleDelete(index, row) {
      console.log(index, row);
      this.$confirm("是否删除该弹窗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          apiShout.getdeleteWin({ id: row.id }).then((res) => {
            console.log("删除：", res.data);
            if (res.data.code == 200) {
              this.$message({
                message: "恭喜你,成功删除弹窗信息",
                type: "success",
              });
              this.winList();
            } else {
              this.$message.error(res.data.msg);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    //每页的条数分页
    handleSizeChange(size) {
      //console.log("size:", size);
      this.pageSize = size;
      this.winList();
    },
    //当前在第几页
    handleCurrentChange(pageCount) {
      //console.log("pageCount:", pageCount);
      this.pageCount = pageCount;
      this.winList();
    },
    handleClose() {
      this.dialogVisible = false;
      this.formInline = {};
      this.formInline.photoUrl = "";
      this.valueTrigger = "";
      this.changTime = [];
      this.value = "";
      this.valueIn = "";
      this.valueType = "";
      this.valueNum = "";
    },
    handleClose1() {
      this.dialogVisible1 = false;
      this.formInline = {};
      this.formInline.photoUrl = "";
      this.valueTrigger = "";
      this.changTime = [];
      this.value = "";
      this.valueIn = "";
      this.valueType = "";
      this.valueNum = "";
    },
    ImagehandleClose() {
      this.dialogVisibleImage = false;
    },
    handleCloseImage() {
      this.imageVisible = false;
    },
    handleCloseImage1() {
      this.imageVisible1 = false;
    },
    quxiao() {
      this.dialogVisible1 = false;
      this.formInline = {};
      this.formInline.photoUrl = "";
      this.changTime = [];
      this.value = "";
      this.valueIn = "";
      this.valueType = "";
      this.valueNum = "";
      this.valueTrigger = "";
    },
  },
};
</script>

<style lang="less" scoped>
.bottom-right {
  float: right;
}
.buttons {
  .el-button {
    width: 145px;
    height: 30px;
    border-radius: 25px;
    margin-left: 50px;
    margin-top: 20px;
    background: #d78673;
    color: #ffffff;
    font-size: 19px;
    padding: 0;
    float: right;
  }
}

.top-btn {
  background: #d78673;
  margin-left: 30px;
  color: #ffffff;
  margin-top: 10px;
}
.bottom-fen {
  margin-top: 10px;
}
.bottom-left {
  float: left;
}
//弹框
/deep/ .el-dialog__body {
  margin: 20px;
}

// table 表格所有的格式
.tables {
  .el-table::before {
    height: 0;
  }
  .el-table {
    td,
    th.is-leaf {
      border: none !important;
    }
    tr th {
      font-size: 25px;
      color: #706d6d;
      text-align: center;
    }
    tr td {
      text-align: center;
      font-size: 25px;
      color: #a8a8a8;
    }
  }
}
/deep/ .el-table .el-table__cell {
  padding: 12px 0;
  min-width: 0;
  box-sizing: border-box;
  text-overflow: ellipsis;
  vertical-align: middle;
  position: relative;
  text-align: center;
}
/deep/ .el-table th.el-table__cell > .cell {
  display: inline-block;
  box-sizing: border-box;
  position: relative;
  vertical-align: middle;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
  font-size: 18px;
}
/deep/ .el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
  border-bottom: none;
}
/deep/ th.el-table__cell.is-leaf {
  border-bottom: none;
}
/deep/.el-table td,
.el-table th.is-leaf {
  border-bottom: none !important;
}
/deep/.el-table .cell {
  text-align: center !important;
}
/deep/.el-table thead tr th.is-leaf {
  border: none;
  border-right: none;
}
</style>